<template>
  <v-flex
    class="animate__animated animate__fadeIn pa-3 pr-6"
    v-if="getLoggedUser.admin"
    xs12
    md4
  >
    <v-card
      height="120"
      class="expande-horizontal pa-3"
      style="flex-wrap: wrap;"
    >
      <div
        class="expande-horizontal"
        style="flex-wrap: nowrap; align-items: center;"
      >
        <div class="coluna">
          <h4 class="font-color--secondary fonte fonte-subtitulo">
            Minha Assinatura
          </h4>
        </div>
        <v-spacer></v-spacer>
      </div>
      <div
        class="expande-horizontal"
        style="flex-wrap: nowrap; align-items: center;"
      >
        <v-list style="width: 100%;" class="pa-0 ma-0 fonte" nav>
          <v-list-item class="pa-0 ma-0">
            <v-avatar class="mr-2" :color="$theme.secondary">
              <!-- <v-img></v-img> -->
              <v-icon color="white">mdi-book-outline</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ subscription.plan_id.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Próxima cobrança em
                {{ $moment(subscription.creation_of_next_charge).fromNow() }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="openMyLink" :color="$theme.secondary" icon>
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      subscription: {
        plan_id: {}
      }
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    getMySubscription() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/show-my-subscription")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.subscription = res.data.subscription;
        })
        .catch(e => {});
    }
  },
  mounted() {
    this.getMySubscription();
  }
};
</script>
