<template>
  <v-card height="120" class="expande-horizontal pa-3" style="flex-wrap: wrap;">
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div class="coluna">
        <h4 class="font-color--secondary fonte fonte-subtitulo">
          Minha loja
        </h4>
      </div>
      <v-spacer></v-spacer>
    </div>
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <v-list style="width: 100%;" class="pa-0 ma-0 fonte" nav>
        <v-list-item class="pa-0 ma-0">
          <v-avatar class="mr-2" :color="$theme.secondary">
            <!-- <v-img></v-img> -->
            <v-icon color="white">mdi-storefront</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ getLoggedUser.tenant[0].nome }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Acesse sua loja
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="openMyLink" :color="$theme.secondary" icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClipboardJS from "clipboard";

export default {
  data() {
    return {
      myLink: ""
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    linkMount() {
      this.myLink = `https://${this.getLoggedUser.tenant[0].link_menurocket}.menurocket.com.br/#/`;
      new ClipboardJS(".btn1");
    },
    openMyLink() {
      window.open(this.myLink, "_blank");
    }
  },
  mounted() {
    this.linkMount();
  }
};
</script>
