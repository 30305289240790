<template>
  <canvas :height="height" :id="this.id"></canvas>
</template>

<script>
import Chart from "chart.js";
export default {
  props: ["id", "id2", "type", "labels", "height", "dataPayments"], 
  data() {
    return {};
  },
  watch: {
    dados() {
      this.montaChart();
    }
  },
  methods: {
    montaChart() {
      var tela = document.getElementById(this.id).getContext("2d");
      var chart = new Chart(tela, {
        // The type of chart we want to create
        type: this.type,

        // The data for our dataset
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.id,
              backgroundColor: [
                "rgba(54, 162, 235, 1)",
                "rgba(222, 222, 222, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(111, 111, 111, 1)"
              ],
              data: this.dataPayments, // data: this.dados
              fontColor: "rgba(255,255,255,1)",
              color: "0BC1D9"
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: true,
            position: "right",
            labels: {
              fontSize: 14,
              padding: 10
            },
            fontColor: "rgba(255,255,255,1)",
            color: "0BC1D9"
          }
        }
      });
    }
  },
  mounted() {
    this.montaChart();
  }
};
</script>
